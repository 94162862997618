export interface Answer {
  value?: number;
  comment?: string;
}

export interface Answers {
  env?: Answer;
  copro?: Answer;
  distrib?: Answer;
  confort?: Answer;
  vue?: Answer;
  prix?: Answer;
  match?: Answer;
}

export interface Form {
  id?: string;
  answers?: Answers;
  timestamp?: number;
  name?: string;
  visitDate?: number;
}

export async function loadForm(id: string, token: string): Promise<Form> {
  const response = await fetch("/api/load", {
    method: "POST",
    mode: "same-origin",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-token": token,
    },
    body: JSON.stringify({
      id,
    }),
  });

  console.log(response);
  if (response.status !== 200) {
    throw new Error(`Unexpected response from save function: ${response}`);
  }
  const responseBody = await response.json();
  console.log(responseBody);

  return responseBody?.form as Form;
}

export async function saveForm(form: Form, token: string): Promise<number> {
  console.log("saving:", form);
  const response = await fetch("/api/save", {
    method: "POST",
    mode: "same-origin",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-token": token,
    },
    body: JSON.stringify({
      form,
    }),
  });

  console.log("save response:", response);

  if (response.status !== 200) {
    throw new Error(`Unexpected response from save function: ${response}`);
  }

  const responseBody = await response.json();
  console.log("save response body:", responseBody);
  return responseBody?.timestamp as number;
}
