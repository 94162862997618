export default function Icon(props: { id: number }) {
  return (
    <i
      className="noselect"
      style={{
        fontFamily: "fa",
        fontStyle: "normal",
        fontWeight: 900,
      }}
    >
      {String.fromCodePoint(props.id)}
    </i>
  );
}
