import React, { useCallback, useEffect, useState } from "react";
import Icon from "./Icon";
import Input from "./components/Input";
import Stars from "./components/Stars";
import { Answer, Answers, Form, loadForm, saveForm } from "./api";

const background1 = "#f0f0ff";
const background2 = "#fafaff";

interface Question {
  id: keyof Answers;
  icon: number;
  color: string;
  title: string;
  details: string;
}

const questions: Question[] = [
  {
    id: "env",
    icon: 0xf5a0,
    color: "#633",
    title: "L'environnement",
    details: "Adresse, transports, commerces, écoles...",
  },
  {
    id: "copro",
    icon: 0xe587,
    color: "#393",
    title: "La copropriété",
    details: "Aspect, parties communes, services, voisinnage...",
  },
  {
    id: "distrib",
    icon: 0xf546,
    color: "#f93",
    title: "La distribution du bien",
    details: "Surfaces, agencement, nombre de pièces...",
  },
  {
    id: "confort",
    icon: 0xf2cc,
    color: "#39f",
    title: "Les éléments de confort",
    details: "Balcon, garage, cave, double vitrage, volet roulant...",
  },
  {
    id: "vue",
    icon: 0xf06e,
    color: "#ec3",
    title: "La clarté et la vue",
    details: "Luminosité, exposition, vis-à-vis, traversant...",
  },
  {
    id: "prix",
    icon: 0xf24e,
    color: "#339",
    title: "Le rapport qualité / prix",
    details: "Prix au m2, charges, énergie, travaux...",
  },
  {
    id: "match",
    icon: 0xf164,
    color: "#f9f",
    title: "L'adéquation",
    details: "Le bien correspond à ma recherche",
  },
];

let isSaving = false;
let lastUpdate = -1;
let state: Form = undefined as any;

function App() {
  const params = new URL(document.location as any as string).searchParams;
  const id = params.get("id") || undefined;
  const token = params.get("token") || undefined;
  const [currentForm, setCurrentForm] = useState({ id } as Form);
  if (!state) {
    state = { id };
  }
  const [isDoingSomething, setDoingSomething] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState(
    undefined as NodeJS.Timeout | undefined
  );
  const [currentUpdate, setCurrentUpdate] = useState(0);

  useEffect(() => {
    async function load() {
      setDoingSomething(true);
      state = await loadForm(id as string, token as string);
      setCurrentForm(state);
      setDoingSomething(false);
    }
    if (id && token) {
      load();
    }
    return () => {};
  }, [id, token]);

  const scheduleUpdate = useCallback(() => {
    async function callback() {
      if (currentUpdate === lastUpdate) {
        // Nothing to update
        return;
      }
      if (isSaving) {
        if (currentTimeout) {
          clearTimeout(currentTimeout);
        }
        setCurrentTimeout(setTimeout(callback, 1000));
      } else {
        isSaving = true;
        setDoingSomething(true);
        lastUpdate = currentUpdate;
        const timestamp = await saveForm(state, token as string);
        state = { ...state, timestamp };
        setCurrentForm(state);
        setDoingSomething(false);
        isSaving = false;
      }
    }

    setCurrentUpdate(currentUpdate + 1);
    if (currentTimeout) {
      clearTimeout(currentTimeout);
      setCurrentTimeout(undefined);
    }
    setCurrentTimeout(setTimeout(callback, 1000));
  }, [currentTimeout, isDoingSomething, state, currentUpdate]);

  const updateName = useCallback(
    (newName?: string | number) => {
      state = {
        ...state,
        name: typeof newName === "string" ? newName : undefined,
      };
      setCurrentForm(state);
      scheduleUpdate();
    },
    [scheduleUpdate, state]
  );

  const updateVisitDate = useCallback(
    (newVisitDate?: string | number) => {
      state = {
        ...state,
        visitDate: typeof newVisitDate === "number" ? newVisitDate : undefined,
      };
      setCurrentForm(state);
      scheduleUpdate();
    },
    [scheduleUpdate, state]
  );

  const updateAnswerValue = useCallback(
    (id: keyof Answers, newValue?: number) => {
      const currentAnswers: Answers = state.answers || {};
      const currentAnswer: Answer = currentAnswers[id] || {};
      state = {
        ...state,
        answers: {
          ...currentAnswers,
          [id]: { ...currentAnswer, value: newValue },
        },
      };
      setCurrentForm(state);
      scheduleUpdate();
    },
    [scheduleUpdate, state]
  );

  const updateAnswerComment = useCallback(
    (id: keyof Answers, newComment?: string | number) => {
      const currentAnswers: Answers = state.answers || {};
      const currentAnswer: Answer = currentAnswers[id] || {};
      state = {
        ...state,
        answers: {
          ...currentAnswers,
          [id]: {
            ...currentAnswer,
            comment: typeof newComment === "string" ? newComment : undefined,
          },
        },
      };
      setCurrentForm(state);
      scheduleUpdate();
    },
    [scheduleUpdate, state]
  );

  return (
    <div
      style={{
        alignItems: "stretch",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          alignItems: "center",
          background: background1,
          borderBottom: "1px solid #cacacf",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          padding: 15,
        }}
      >
        <span style={{ fontWeight: "bold", marginBottom: 5 }}>
          Fiche d'appréciation
        </span>

        {currentForm.timestamp && (
          <Input
            type="text"
            placeholder="Nom..."
            value={currentForm.name}
            width={200}
            onChange={updateName}
          />
        )}

        {currentForm.timestamp && (
          <Input
            type="datetime"
            value={currentForm.visitDate}
            width={200}
            onChange={updateVisitDate}
          />
        )}
      </div>

      <div
        style={{
          alignItems: "stretch",
          background: background2,
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          height: 0,
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <div
          style={{
            alignItems: "stretch",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            justifyContent: "flex-start",
            padding: 10,
          }}
        >
          {currentForm.timestamp &&
            questions.map((q) => (
              <div
                key={q.id}
                style={{
                  alignItems: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  justifyContent: "flex-start",
                  padding: "20px 10px",
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      flexGrow: 1,
                      fontWeight: "bold",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        color: q.color,
                        fontSize: 24,
                        width: 40,
                        textAlign: "left",
                      }}
                    >
                      <Icon id={q.icon} />
                    </div>
                    <span style={{ fontWeight: "bold" }}>{q.title}</span>
                  </div>
                  <Stars
                    max={5}
                    value={
                      (currentForm.answers &&
                        currentForm.answers[q.id]?.value) ||
                      0
                    }
                    color={q.color}
                    onChange={(newValue) => updateAnswerValue(q.id, newValue)}
                  />
                </div>
                <span style={{ opacity: 0.75 }}>{q.details}</span>
                <Input
                  type="multi"
                  value={
                    (currentForm.answers &&
                      currentForm.answers[q.id]?.comment) ||
                    undefined
                  }
                  align="left"
                  placeholder="Commentaire..."
                  onChange={(newComment) =>
                    updateAnswerComment(q.id, newComment)
                  }
                />
              </div>
            ))}
        </div>
      </div>

      <div
        style={{
          alignItems: "center",
          background: background1,
          borderTop: "1px solid #cacacf",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          fontSize: "0.85em",
          justifyContent: "flex-start",
          padding: 5,
        }}
      >
        <span>
          <Icon
            id={
              isDoingSomething
                ? 0xf110
                : currentForm.timestamp
                ? 0xf00c
                : 0xf071
            }
          />
          &nbsp;{" "}
          {isDoingSomething
            ? currentForm.timestamp
              ? "Enregistrement..."
              : "Chargement..."
            : currentForm.timestamp
            ? `Enregistré ${new Date(
                currentForm.timestamp as number
              ).toLocaleString("fr-FR")}`
            : "URL invalide"}
        </span>
      </div>
    </div>
  );
}

export default App;
