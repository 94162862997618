import { useState } from "react";

const defaultMaxLength = 500;
const textAreaMaxLineLength = 36;

function formatDate(value: number): string {
  return new Date(value).toISOString().substring(0, 16);
}

function parseDate(value: string): number {
  return Date.parse(value);
}

export default function Input(props: {
  value?: string | number;
  type: "text" | "datetime" | "multi";
  width?: number;
  align?: "center" | "left";
  placeholder?: string;
  maxLength?: number;
  onChange?: (value?: string | number) => void;
}) {
  const initialValue =
    props.type === "datetime"
      ? typeof props.value === "number"
        ? formatDate(props.value as number)
        : undefined
      : props.value;

  const [currentValue, setCurrentValue] = useState(initialValue);

  return props.type === "multi" ? (
    <textarea
      defaultValue={initialValue}
      placeholder={props.placeholder}
      onBlur={() => {
        if (props.onChange) {
          props.onChange(currentValue);
        }
      }}
      maxLength={
        props.maxLength === undefined ? defaultMaxLength : props.maxLength
      }
      cols={textAreaMaxLineLength}
      rows={
        currentValue === undefined
          ? 1
          : Math.max(1, (currentValue as string).split("\n").length)
      }
      onChange={(e) => {
        const lines = e.target.value.replaceAll("\r", "").split("\n");
        const linesSplit: string[] = [];

        for (let line of lines) {
          if (line.length > textAreaMaxLineLength) {
            for (let i = 0; i < line.length; i += textAreaMaxLineLength) {
              const sub = line.substring(i, i + textAreaMaxLineLength);
              linesSplit.push(sub);
            }
          } else {
            linesSplit.push(line);
          }
        }

        const newValue = linesSplit.join("\n");
        e.target.value = newValue;
        setCurrentValue(newValue);
      }}
      style={{
        textAlign: props.align,
        width: props.width === undefined ? "auto" : props.width,
      }}
    ></textarea>
  ) : (
    <input
      type={props.type === "datetime" ? "datetime-local" : "text"}
      width={props.width === undefined ? "auto" : props.width}
      defaultValue={initialValue}
      placeholder={props.placeholder}
      onBlur={() => {
        if (props.onChange) {
          props.onChange(currentValue);
        }
      }}
      onChange={(e) => {
        const text = e.target.value;
        if (props.type === "datetime") {
          setCurrentValue(parseDate(text));
        } else {
          setCurrentValue(text);
        }
      }}
      maxLength={
        props.maxLength === undefined ? defaultMaxLength : props.maxLength
      }
      style={{
        textAlign: props.align,
      }}
    ></input>
  );
}
