import { useState } from "react";
import Icon from "../Icon";

export default function Stars(props: {
  max: number;
  value: number;
  color: string;
  onChange?: (value?: number) => void;
}) {
  const [currentValue, setCurrentValue] = useState(props.value);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
      }}
    >
      {Array.from(Array(props.max).keys()).map((i) => (
        <div
          key={i}
          style={{
            cursor: "pointer",
            fontSize: 24,
            color: currentValue >= i + 1 ? props.color : "#ddd",
          }}
          onClick={() => {
            setCurrentValue(i + 1);
            if (props.onChange) {
              props.onChange(i + 1);
            }
          }}
        >
          <Icon id={0xf005} />
        </div>
      ))}
    </div>
  );
}
